* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  text-align: center;
  scroll-behavior: smooth;
}

html, body {
  margin: 0;
  padding: 0;
  border: 0;
  scroll-behavior: smooth;
  font-family: "Lato";
  font-display: swap;   
  word-break: break-word;
}

main {
  position: relative;
  width: 100%;
  height: 100%;
}

a { text-decoration: none; }

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

iframe { background-size: contain; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img { width: inherit; }

button, input, textarea, select {
  border: none;
  padding: 0;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #274CD0;
}

::-webkit-scrollbar-thumb {
  background-color: #BFD9FF;
  border-radius: 8px;
}

button { cursor: pointer; }

body { overflow-x: hidden; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  scroll-behavior: smooth;
}